// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p>
          Nature Parks of Europe
        </p>
        <p>
          <a
            className="App-link"
            href="https://www.instagram.com/natureparkseurope/"
            target="_blank"
          >
            instagram
          </a>
        </p>
        <p>
          <a
            className="App-link"
            href="https://www.facebook.com/natureparkseurope"
            target="_blank"
          >
            facebook
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
